/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import PaypalMessages from '../../component/PaypalMessages';
import { PAYPAL_PLACEMENT_CATEGORY_PAGE } from '../../component/PaypalSmartButtons/PaypalSmartButtons.config';

const addPayPalMessagesToCategoryPage = (args, callback) => {
    const CategoryPage = callback(...args);
    const children = Children.toArray(CategoryPage.props.children);

    return (
        <>
            { cloneElement(CategoryPage, { ...CategoryPage.props }, [
                <PaypalMessages
                  key={ PAYPAL_PLACEMENT_CATEGORY_PAGE }
                  page={ PAYPAL_PLACEMENT_CATEGORY_PAGE }
                />,
                ...children
            ]) }
        </>
    );
};

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            render: addPayPalMessagesToCategoryPage
        }
    }
};
