/* eslint-disable react/forbid-dom-props */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-danger */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CategoryPageComponent as SourceCategoryPageComponent } from 'SourceRoute/CategoryPage/CategoryPage.component';
import ContentWrapper from 'Component/ContentWrapper';
import MusiqueDepotCmsBlocks from 'Component/MusiqueDepotCmsBlocks';
import {
    MD_WIDGET_CMS_BLOCK_AREA_PLP_TOP,
    MD_WIDGET_CMS_BLOCK_ENTITY_TYPE_CATEGORY,
} from 'Component/MusiqueDepotCmsBlocks/MusiqueDepotCmsBlocks.config';
import { isCrawler, isSSR } from 'Util/Browser';

import './CategoryPage.override.style.scss';

/** @namespace Scandipwa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPageComponent {
    /**
     * Overridden to update misc height for pre-render
     */
    renderMiscellaneous() {
        return (
            <aside
              block="CategoryPage"
              elem="Miscellaneous"
              mods={ { isCrawler: isSSR() || isCrawler() } }
            >
                { this.renderItemsCount() }
                <div
                  block="CategoryPage"
                  elem="MiscellaneousLayoutWrapper"
                >
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { this.renderLayoutButtons() }
                      { this.renderCategorySort() }
                  </div>
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { this.renderFilterButton() }
                  </div>
                </div>
            </aside>
        );
    }

    renderPlpTopCMSBlockWidgets() {
        const { plpCategoryId } = this.props;

        if (!plpCategoryId) {
            return null;
        }

        return (
            <MusiqueDepotCmsBlocks
              referenceContainer={ MD_WIDGET_CMS_BLOCK_AREA_PLP_TOP }
              entityType={ MD_WIDGET_CMS_BLOCK_ENTITY_TYPE_CATEGORY }
              entityId={ plpCategoryId }
            />
        );
    }

    render() {
        const hideProducts = !this.displayProducts();
        const { totalItems } = this.props;

        return (
            <main block="CategoryPage" mods={ { noResults: totalItems === 0 } }>
                { this.renderPlpTopCMSBlockWidgets() }
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts },
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default CategoryPageComponent;
