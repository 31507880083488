/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable max-len */

import { CategoryItemsCountComponent as SourceCategoryItemsCountComponent } from 'SourceComponent/CategoryItemsCount/CategoryItemsCount.component';
import TextPlaceholder from 'Component/TextPlaceholder';

/** @namespace Scandipwa/Component/CategoryItemsCount/Component */
export class CategoryItemsCountComponent extends SourceCategoryItemsCountComponent {
    static defaultProps = {
        isMatchingListFilter: false,
    };

    render() {
        const {
            totalItems,
            isMatchingListFilter,
        } = this.props;

        return (
            <p block="CategoryPage" elem="ItemsCount">
                <TextPlaceholder
                  content={ (!isMatchingListFilter
                      ? __('%s items found', totalItems)
                      : __('%s items found', totalItems)
                  ) }
                />
            </p>
        );
    }
}

export default CategoryItemsCountComponent;
