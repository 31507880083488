import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CategoryProductListContainer as SourceCategoryProductListContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/CategoryProductList/CategoryProductList.container';

import './CategoryProductList.override.style';

export { mapDispatchToProps };

/** @namespace Scandipwa/Component/CategoryProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => {
    const {
        ConfigReducer: {
            device: { isMobile: isInfiniteLoaderEnabled = false } = {},
        } = {},
    } = state;

    const stateToPropsMap = {
        ...sourceMapStateToProps(state),
        isInfiniteLoaderEnabled,
    };

    return stateToPropsMap;
};

/** @namespace Scandipwa/Component/CategoryProductList/Container */
export class CategoryProductListContainer extends SourceCategoryProductListContainer {
    static propTypes = {
        ...super.propTypes,
        isInfiniteLoaderEnabled: PropTypes.bool,
    };

    static defaultProps = {
        ...super.defaultProps,
        isInfiniteLoaderEnabled: false,
    };

    containerProps() {
        const { isInfiniteLoaderEnabled } = this.props;

        return {
            ...super.containerProps(),
            isInfiniteLoaderEnabled,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductListContainer);
